<template>
<div class="container">
  <TheNavbar />
  <router-view/>
  <footer>
    <div class="w-100 flex justify-center py-16">
      Copyright © 2022 - Vostok.ninja
    </div>
  </footer>
</div>
</template>


<script setup>

import TheNavbar from '@/components/TheNavbar.vue';

import store from './store/index';
import { provide } from 'vue';

provide('store', store);



</script>


<style>

@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Montserrat:wght@300;400;600;700&display=swap');




body{

  margin: 0;
  padding: 0;
  background-color: #EEF0F2;
  color: #191308;
  font-family: 'Montserrat', sans-serif;
  letter-spacing:0.1px;
}


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.container{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

</style>
