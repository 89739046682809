import { reactive } from "vue";


const qs = require('qs');



const state = reactive({

  nombreDeRecettesToShow : 9,
  recettes: '',
  randomRecettes: ''
   
})




const methods = {

    async  searchRecette(searchValue, page){

        const findRecette = qs.stringify({
        sort: ['Titre:asc'],
        filters: {
          Titre: {
            $containsi: searchValue.toLocaleLowerCase()
          }
        },
          populate: '*',
          pagination: {
            pageSize: 9,
            page: page,
          }
        });

      
        let response = await fetch(`https://gestionrecettes.vostok.ninja/api/recettes?${findRecette}`);
        let data = await response.json();
        state.randomRecettes = data.data;
      
      
      },

      
      async fetchRecipies(page){
        if(page === 20){
          return;
        }
        const queryRecette = qs.stringify({
      
        sort: ['Titre:asc'],
          populate: '*',
          pagination: {
            pageSize: 100,
            page: page,
          }
        });
      
      
       
        let response = await fetch(`https://gestionrecettes.vostok.ninja/api/recettes?${queryRecette}`);
        let data = await response.json();

        if(state.recettes.data){
         
            state.recettes.data = state.recettes.data.concat(data.data);
        

      
        }else{
          state.recettes = data;
        }
        if(state.recettes.meta.pagination.pageCount > page){
          methods.fetchRecipies(page + 1);
        }
        methods.getRamdomRecipies();
      
      },


      getRamdomRecipies(){


        if(state.recettes && state.recettes.data.length > state.nombreDeRecettesToShow){
          const randomRecipies = [];
          for(let i = 0; i < state.nombreDeRecettesToShow; i++){
            const randomIndex = Math.floor(Math.random() * state.recettes.data.length);
            if(randomRecipies.includes(state.recettes.data[randomIndex])){
              i--;
            }else{
              randomRecipies.push(state.recettes.data[randomIndex]);
            }
          
          }
          state.randomRecettes =  randomRecipies;
        }else{
          state.randomRecettes =  state.recettes.data;
        }
      }

      
    
      



}





const getters = {};

export default {
  state,
  methods,
  getters,
};
