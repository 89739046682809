import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Rapido from '../views/Rapido.vue'
import Recette from '../views/Recette.vue'
import Tag from '../views/Tag.vue'
import Toutes from '../views/Toutes.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/rapido',
    name: 'Rapido',
    component: Rapido
  },
  {
    path: '/toutes',
    name: 'Toutes',
    component: Toutes
  },
  {
    path: '/recette/:slug', 
    name: 'Recette',
    component: Recette
  },
  {
    path: '/tag/:slug', 
    name: 'Tag',
    component: Tag
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
