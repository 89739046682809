<template>

<div class="flex justify-center">

<transition-group name="slide">

  <div v-if="recettesCurrentTag"   class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-40">
    <div v-for="(recette, index) in recettesCurrentTag" :key="index" class="cards">
      <div class="max-w-sm rounded overflow-hidden shadow-lg p-6">
      <router-link :to="'/recette/' + recette.attributes.Slug">
        <div class="w-100 flex justify-start gap-3 h-100">
          <img class="thumbnail-accueil w-36" :src="`https://gestionrecettes.vostok.ninja${recette.attributes.Photoprincipale.data.attributes.formats.thumbnail.url}`">
          
         <div class="flex flex-col justify-between">
           <div>

                 <h2>
                
                    {{nextLineAfter(recette.attributes.Titre)[0]}}
    
                  
                 </h2>
                  
          
        <p class="text-gray-700 font-light text-sm pt-2">{{nextLineAfter(recette.attributes.Titre)[1]}}</p>
           </div>
          
 <div class="scale-75">
          
                      <i class="fas fa-star-half " v-if="recette.attributes.note.data.attributes.Note == 0.5"></i>
                      <i class="fas fa-star scale-75" v-if="recette.attributes.note.data.attributes.Note >= 1"></i>
                      <i class="fas fa-star-half" v-if="recette.attributes.note.data.attributes.Note == 1.5"></i>
                      <i class="fas fa-star" v-if="recette.attributes.note.data.attributes.Note >= 2"></i>
                      <i class="fas fa-star-half"  v-if="recette.attributes.note.data.attributes.Note == 2.5"></i>
                      <i class="fas fa-star" v-if="recette.attributes.note.data.attributes.Note >= 3"></i>
                      <i class="fas fa-star-half"  v-if="recette.attributes.note.data.attributes.Note == 3.5"></i>
                      <i class="fas fa-star"  v-if="recette.attributes.note.data.attributes.Note >= 4"></i>
                      <i class="fas fa-star-half" v-if="recette.attributes.note.data.attributes.Note == 4.5"></i>
                      <i class="fas fa-star"  v-if="recette.attributes.note.data.attributes.Note >= 5"></i>
                  
                    <span class="text-xs pl-2">{{recette.attributes.note.data.attributes.Note}}/5</span>
                      <p class="text-xs pt-2">{{recette.attributes.temp.data.attributes.tempsdepreparation}}</p>
                    </div>  
                    
         </div>


          
       
        </div>
      </router-link>
          <div class="px-6 pt-8 pb-2 grid grid-cols-2 lg:flex lg:justify-start" >
            <div v-for="(tag, index) in recette.attributes.tags.data" :key="index" class="flex justify-center" >
               <router-link :to="`/tag/${tag.attributes.Slug}`" @click="getAllTagRecettes(tag.attributes.Slug)">
             
                 <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                  #{{tag.attributes.Nom}}
                  </span>
                </router-link>
            </div>
           

          </div>
        </div>
    </div>

  </div>  </transition-group>
  </div> 

</template>


<script setup>

import {  onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const qs = require('qs');

const recettesCurrentTag = ref('');






async function getAllTagRecettes(slug) {

     

      let niceSlug = route.params.slug;

        if(slug){
          niceSlug = slug;
        }

        const findRecetteWithTags = qs.stringify({
        sort: ['Titre:asc'],
        filters: {
          tags: {
            slug:{
                $containsi: niceSlug
            }
          }
        },
          populate: '*',
          pagination: {
            pageSize: 100,
            page: 1
          }
        });

      
        let response = await fetch(`https://gestionrecettes.vostok.ninja/api/recettes?${findRecetteWithTags}`);
        let data = await response.json();
        recettesCurrentTag.value = data.data
      
    
      


} 


// format text with next line after ( 
  function nextLineAfter(text){
    let titreFormated = text.split("(");
    let titre = titreFormated[0];
    let provenance = null;
    if(titreFormated[1]){
      provenance = "(" + titreFormated[1];
    }


    return [ titre, provenance];
  }


onMounted(() => {

     
  recettesCurrentTag.value = '';
  getAllTagRecettes();
})





</script>


<style>


</style>