<template>
<header>
 <nav class="grid grid-cols-1 md:grid-cols-2 pt-10 mb-4 ">
   <div class="mx-auto md:ml-16 order-2 md:order-1">


   <div class="h-100 w-100 flex pt-6 ">

     <div>



      <div class="w-100 flex justify-center ">
        <ul class="inline-block">
          <li @click="getRamdomRecipiesCheckLocal()">
     
            <i class="fas fa-dice fa-2x mr-2 ml-1"></i>
         
              <p class="inline-block">
                   Random moi des recettes
              </p>
            
            </li>
          <li><router-link to="/rapido" @click="whatToSearch = ''">
            <i class="fas fa-clock fa-2x mr-2 ml-1"></i>
          <p class="inline-block">Recettes Rapido Ninja</p>
          </router-link>

          </li>
          <li>
            <router-link to="/toutes" @click="whatToSearch = ''">
            <i class="fas fa-clipboard-list fa-2x mr-2 ml-1"></i>
          <p class="inline-block">
            voir toutes
          </p>
          </router-link>
          </li>
         
        </ul>
      </div>
      <div class="flex justify-center px-10 md:hidden pt-6 ">
        <div class="mb-3">
          <div class="input-group flex items-stretch w-full mb-4 rounded ">
            <input @keyup="search()" type="search" v-model="whatToSearch" class="form-control relative flex-auto min-w-0 block  px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-900 focus:outline-none" placeholder="Chercher" aria-label="Search" aria-describedby="button-addon2">
            <span class="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded cursor-pointer" @click="search()">
      
            </span>
          </div> 
        </div>
       </div>
  </div>
   </div>
      </div>

   
  <div class="flex flex-col items-center md:items-end order-1 md:order-2">
   
    <div class="pt-6"> 
      <div class="flex justify-end items-center">
        <img src="../assets/images/logofood.png" alt="logo" width="60" class="pb-6 pr-2">
        <div class="flex justify-end items-end">
        <h1> RECETTES<span class=" text-xs md:text-lg"> VOSTOK.NINJA</span></h1>
        </div>
        

      </div>
      </div>

<div class="hidden justify-end w-full md:flex">
  <div class="mb-3 w-96 -mx-6">
    <div class="input-group flex items-stretch w-full mb-4 rounded">
      <input @keyup="search()"  type="text" v-model="whatToSearch" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-900 focus:outline-none" placeholder="Chercher" aria-label="Search" aria-describedby="button-addon2">
      <span class="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded cursor-pointer" @click="search()">
      </span>
    </div> 
  </div>
  </div>
</div>
   
 </nav>
</header>
</template>

<script setup>

import { useRoute, useRouter } from 'vue-router';
import { ref, inject } from 'vue';


const store = inject("store");
const whatToSearch = ref('');
const route = useRoute();
const router = useRouter();

function search(){

  if(whatToSearch.value.length > 1){
    store.methods.searchRecette(whatToSearch.value.toLocaleLowerCase(), 1);
    if(route.name !== '/'){
      router.push({path: '/'});
    }
  }else{
    store.methods.getRamdomRecipies();
  }
}



function getRamdomRecipiesCheckLocal(){
  whatToSearch.value = '';
 if(route.path !== "/"){

    router.push('/');

 }else{
    store.methods.getRamdomRecipies();
 }
 
}





</script>

<style scoped>

ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

}

ul li {
      padding-bottom: 8px;
      font-family: 'Bangers', cursive;
      padding-right: 30px;
      cursor: pointer;   
}



ul li p{
    transition: all 0.3s ease-in-out;
    transition-delay: 0.1s;
  
}

ul li p:hover { 
    
      transform: translate(4px, 0);
    
}





h1{
  font-family: 'Bangers', cursive;
  font-size: 64px !important;
  color: #191308;
  line-height: 10px !important;
}











</style>
