<template>
<div v-if="currentRecette" class="w-100 pt-16">
    <div class="grid grid-cols-1 md:grid-cols-4">
           

  <div class="imageRecette col-span-1 rounded-t md:rounded-t-none  md:rounded-l" :style="{backgroundImage:`url(https://gestionrecettes.vostok.ninja/${currentRecette.attributes.Photoprincipale.data.attributes.url})`}">
  </div>


            <div class="col-span-1 rounded-b md:rounded-b-none md:col-span-3 p-8 carreInfos bg-opacity-50  md:rounded-r containerInfosRecettes">
                <h2 class="text-3xl">{{nextLineAfter(currentRecette.attributes.Titre)[0]}}</h2>
                <h3 v-if="nextLineAfter(currentRecette.attributes.Titre)[1]">{{nextLineAfter(currentRecette.attributes.Titre)[1]}}</h3>
                 <div class="text-black pt-4">
                    <i class="fas fa-star-half " v-if="currentRecette.attributes.note.data.attributes.Note == 0.5"></i>
                    <i class="fas fa-star scale-75" v-if="currentRecette.attributes.note.data.attributes.Note >= 1"></i>
                    <i class="fas fa-star-half" v-if="currentRecette.attributes.note.data.attributes.Note == 1.5"></i>
                    <i class="fas fa-star" v-if="currentRecette.attributes.note.data.attributes.Note >= 2"></i>
                    <i class="fas fa-star-half"  v-if="currentRecette.attributes.note.data.attributes.Note == 2.5"></i>
                    <i class="fas fa-star" v-if="currentRecette.attributes.note.data.attributes.Note >= 3"></i>
                    <i class="fas fa-star-half"  v-if="currentRecette.attributes.note.data.attributes.Note == 3.5"></i>
                    <i class="fas fa-star"  v-if="currentRecette.attributes.note.data.attributes.Note >= 4"></i>
                    <i class="fas fa-star-half" v-if="currentRecette.attributes.note.data.attributes.Note == 4.5"></i>
                    <i class="fas fa-star"  v-if="currentRecette.attributes.note.data.attributes.Note >= 5"></i>
                    <span class="text-xs pl-2">{{currentRecette.attributes.note.data.attributes.Note}}/5</span>
                </div>
                <div class="py-8">
                    <p class="text-xs text-black pt-2">{{currentRecette.attributes.temp.data.attributes.tempsdepreparation}}</p>
                </div>
                    <div v-for="(tag, index) in currentRecette.attributes.tags.data" :key="index" class="w-100 inline-block my-1" >
                      <router-link :to="`/tag/${tag.attributes.Slug}`">
                      <span class="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                            #{{tag.attributes.Nom}}
                        </span>
                      </router-link>
                  </div>
            </div>
</div>
<div class="grid grid-cols-1 md:grid-cols-2">
            <div class="col-span-1 pt-8">
                
                <h3 class="text-3xl ml-4 ">
                    Ingrédients
                </h3>
                 <div class="col-span-1  ingredients p-8 " v-html="currentRecette.attributes.Ingredients">
                </div>
                
            </div>
             <div class="col-span-1 pt-8 ">
                
                <h3 class="text-3xl ml-4">
                    Préparation
                </h3>
                   
             <div class="col-span-1 p-8 " v-html="currentRecette.attributes.Etapes">

            </div>
                
            </div>
           
            
       
          
    
               
    </div>

</div>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import { useRoute  } from 'vue-router';
const qs = require('qs');


// const store = inject("store");
const route = useRoute();

const currentRecette = ref(null);


async function getRecette(){


        const findRecette = qs.stringify({
        sort: ['Titre:asc'],
        filters: {
          slug: {
            $eq: route.params.slug
          }
        },
          populate: '*',
          pagination: {
            pageSize: 1,
            page: 1
          }
        });

      
        let response = await fetch(`https://gestionrecettes.vostok.ninja/api/recettes?${findRecette}`);
        let data = await response.json();
        currentRecette.value = data.data[0];
      
      
    
}



onMounted(() => {
  // fetch data from backend
  
  
 getRecette();
 


});


// format text with next line after ( 
  function nextLineAfter(text){
    let titreFormated = text.split("(");
    let titre = titreFormated[0];
    let provenance = null;
    if(titreFormated[1]){
      provenance = "(" + titreFormated[1];
    }


    return [ titre, provenance];
  }



</script>


<style>

li{ 
    padding-bottom: 7px;
}

.ingredients{
    font-family: Helvetica,sans-serif !important;
}

.carreInfos{
    background-color: #49474785;
    color: #EEF0F2;   
}

.imageRecette{
    background-size: cover;
    background-position: center;
    min-height: 250px;

}




.containerInfosRecettes{
  min-height: 300px;
}
</style>